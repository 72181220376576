<template>
    <div style="text-align: center;padding-top: 80px;">
        <span class="scan-top">扫一扫付款</span>
        <br>
        <span class="pay-amount">{{ amount + $t("铢") }}</span>
        <br>
        <div class="code2v-bg">
            <van-image width="200" height="200" :src="payData ? payData.imgdat : ''" />
        </div>
    </div>
</template>
  
<script>
import { Image } from "vant";

export default {
    components: {
        [Image.name]: Image,
    },
    data() {
        return {
            payData: this.$route.params.payData,
            amount: this.$route.params.amount,
        };
    },
    methods: {
    },
};
</script>
<style lang="less" scoped>
.scan-top {
    display: inline-block;
    padding: 10px;
}

.pay-amount {
    color: #f56723;
    font-size: 1.2rem;
    font-weight: bold;
    display: inline-block;
    padding: 10px 0px 20px;
}

.code2v-bg {
    background-color: white;
    display: inline-block;
    padding: 10px;
}
</style>